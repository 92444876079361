import * as React from "react"
import { useEffect, useState } from "react"
import { Frame, addPropertyControls, ControlType, Override } from "framer"

// Custom hook to fetch currency rates
function useCurrencyRates() {
    const [rates, setRates] = useState<{ [key: string]: number } | null>(null)
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState<string | null>(null)

    useEffect(() => {
        async function fetchData() {
            try {
                console.log("Fetching data...")
                const response = await fetch(
                    "https://open.er-api.com/v6/latest/USD"
                )
                const data = await response.json()
                if (!response.ok || data.result !== "success") {
                    throw new Error(
                        `API Error: ${JSON.stringify(data.error || data)}`
                    )
                }
                console.log("Data fetched successfully:", data)
                setRates(data.rates)
                setLoading(false)
            } catch (err) {
                console.error("Error fetching data:", err)
                setError(err.message)
                setLoading(false)
            }
        }
        fetchData()
    }, [])

    return { rates, loading, error }
}

// Override function to use the custom hook
export function CurrencyRatesOverride(): Override {
    const { rates, loading, error } = useCurrencyRates()

    if (loading) {
        return {
            text: "Loading...",
        }
    }

    if (error) {
        return {
            text: `Error: ${error}`,
        }
    }

    const ratesString = Object.entries(rates || {})
        .map(([currency, rate]) => `${currency}: ${rate.toFixed(2)}`)
        .join("\n")

    return {
        text: ratesString,
    }
}

// Component to display currency rates
export function CurrencyRatesComponent(props) {
    const { rates, loading, error } = useCurrencyRates()

    return (
        <Frame {...props}>
            <h1>Currency Rates</h1>
            {loading && <p>Loading...</p>}
            {error && <p>Error: {error}</p>}
            {rates && (
                <ul>
                    {Object.entries(rates).map(([currency, rate]) => (
                        <li key={currency}>
                            {currency}: {rate.toFixed(2)}
                        </li>
                    ))}
                </ul>
            )}
        </Frame>
    )
}

// Adding property controls for customization
addPropertyControls(CurrencyRatesComponent, {
    text: {
        type: ControlType.String,
        title: "Text",
    },
})
